import React, {Component} from 'react';
// import logo from './logo.svg';
import './App.css';
import QRCode from 'qrcode.react';

// Import the functions you need from the SDKs you need
import firebase from "firebase"
// import { getDatabase } from "firebase/database";

import as from './as.png'

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDQ25-js8kI8sDLsL_W0Ea-ZV-z6v2LLws",
    authDomain: "shotify-8b0ac.firebaseapp.com",
    databaseURL: "https://shotify-8b0ac-default-rtdb.firebaseio.com",
    projectId: "shotify-8b0ac",
    storageBucket: "shotify-8b0ac.appspot.com",
    messagingSenderId: "108403079285",
    appId: "1:108403079285:web:1bf5addf1ab32042d8203c",
    measurementId: "G-9BYN2G64ML"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class Post extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{
                // flex: 1,
                // display: 'flex',
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${this.props.image})`
            }}>
                <div style={{
                    // flex: 1,
                    // display: 'flex',
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${this.props.image})`,
                    backdropFilter: `blur(10px)`
                }}>

                </div>
            </div>
        )
    }
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            image: "",
            allowStream: false,
            sessionKey: false,
            screenKey: '',
            allPostsObj: {},
            tailNewPosts: [],
            intervalTime: 3000,
            arrImg: []
        }
    }

    componentDidMount() {
        if (window.localStorage.screenKey && window.localStorage.screenKey != 'false') {
            // el localstorage tiene una screen key
            console.log('hay screen key')
            this.setState({screenKey: window.localStorage.screenKey}, () => {
                this.startListenScreen(window.localStorage.screenKey)
            })
        } else {
            console.log('el localstorage no tiene una screen key')
            firebase.database().ref('screens').push({t: Date.now(), s: false}).then((snap1) => {
                console.log('screen key generada', snap1.key)
                this.setState({screenKey: snap1.key}, () => { // tal vez este set state estaba de mas

                    console.log('comienza el onvalue de la screen key');
                    firebase.database().ref('screens').child(snap1.key).on('value', (snap2) => {
                        if (snap2.val()) {
                            if (snap2.val().s) {
                                window.localStorage.screenKey = snap1.key
                                this.setState({screeKey: snap1.key}, () => {
                                    firebase.database().ref('screens').child(snap1.key).off('value', () => {
                                        console.log('se apago el on value de screens y se debería abrir el linsterscreen', snap1.key)
                                    })
                                })
                                this.startListenScreen(snap1.key)
                            } else {
                                console.log('la pantalla existe pero no esta enlazada')
                            }
                        } else {
                            window.localStorage.removeItem('screenKey')
                            window.location.reload()
                        }
                        // firebase.database().ref('screens').child(snap1.key).off('value', () => {
                        // firebase.database().ref('screens').child(snap1.key).set(null).then(() => {


                        // })
                        // })

                    })
                })
            })
        }
    }

    startListenScreen(s1) {
        firebase.database().ref('screens').child(s1).on('value', (s2) => {
            const s3 = s2.val() // objeto de la pantalla
            if (s3 && s3.s) {
                const s = s3.s // key del evento
                this.setState({sessionKey: s})
                firebase.database().ref('sessions').child(s).on('value', (snap) => {
                    if (!snap.val()) {
                        window.localStorage.removeItem('screenKey')
                        window.location.reload()
                    }
                })//existe o deja de existir el evento
                firebase.database().ref('sessions/' + s + '/b').child('as').on('value', (snap) => {
                    this.setState({allowStream: snap.val()})
                })//allow stream
                firebase.database().ref('posts').child(s).once('value', (snap) => {
                    if (snap.val()) {
                        this.state.allPostsObj = snap.val()
                    } else {
                        this.state.allPostsObj = {}
                    }
                })//get all posts once time
                firebase.database().ref('posts').child(s).orderByChild('t').startAt(Date.now()).on('child_added', (snap) => {
                    console.log(snap.key)
                    let obj = snap.val()
                    obj.key = snap.key
                    this.state.tailNewPosts.push(obj)
                })//get new posts

                let it = null
                firebase.database().ref('sessions/' + s + '/b').child('it').on('value', (snap) => {
                    this.setState({intervalTime: snap.val() * 1000 || 3000}, () => {
                        clearInterval(it)
                        it = setInterval(() => {
                            if (this.state.tailNewPosts.length > 0) {
                                console.log('hay una nueva')

                                // this.setState()
                                this.state.arrImg.unshift(this.state.tailNewPosts[0].i)
                                this.setState({arrImg: this.state.arrImg}, () => {
                                    this.state.allPostsObj[this.state.tailNewPosts[0].key] = this.state.tailNewPosts[0];
                                    this.state.tailNewPosts.shift()
                                    if (this.state.arrImg.length > 1) {
                                        setTimeout(() => {
                                            this.state.arrImg.pop()
                                            this.setState({arrImg: this.state.arrImg})
                                        }, this.state.intervalTime)
                                    }
                                })
                            } else {
                                console.log('no hay nuevas')
                                // console.log(JSON.stringify(this.state.allPostsObj))
                                // if (JSON.stringify(this.state.allPostsObj) != '{}') {
                                const arr = Object.keys(this.state.allPostsObj)
                                const q = arr.length
                                if (q > 0) {
                                    console.log('hay en el historial')
                                    const key = arr[Math.floor(Math.random() * ((q - 1) - 0 + 1) + 0)]
                                    this.state.arrImg.unshift(this.state.allPostsObj[key].i)
                                    this.setState({arrImg: this.state.arrImg}, () => {
                                        if (this.state.arrImg.length > 1) {
                                            setTimeout(() => {
                                                this.state.arrImg.pop()
                                                this.setState({arrImg: this.state.arrImg})
                                            }, this.state.intervalTime)
                                        }
                                    })
                                } else {
                                    console.log('no hay en el historial')
                                }
                            }
                        }, this.state.intervalTime)
                    })
                })//get interval time

            } else {
                console.log('on value no existe o es false el campo s')
                window.localStorage.screenKey = false
                window.location.reload()
            }
        })
    }

    render() {
        return (
            this.state.sessionKey
                ?
                this.state.allowStream
                    ?
                    <div inicio style={{
                        display: 'flex',
                        // flex: 1,
                        width: '100%',
                        height: '100vh',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        // alignItems: 'center'
                    }}>
                        {
                            this.state.arrImg.length > 0
                                ?
                                this.state.arrImg.map((l, i) => {
                                    console.log(l)
                                    return (
                                        <Post key={i} image={l}></Post>
                                    )
                                })
                                :
                                <div inicio style={{
                                    display: 'flex',
                                    flex: 1,
                                    width: '100%',
                                    height: '100vh',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // backgroundImage: <as/>,
                                    backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/shotify-8b0ac.appspot.com/o/muchpic%2Fimg%2Fas-2.jpg?alt=media&token=a8173ce9-bcc1-4b43-88af-df61b217cea1")',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}></div>
                        }
                        <div qr style={{
                            position: 'fixed',
                            right: 0,
                            bottom: 0,
                            padding: 20,
                            backgroundColor: `rgba(255, 255, 255, 0.3)`,
                            borderTopLeftRadius: 15
                        }}>
                            <QRCode value={'https://shot.muchpic.com#' + this.state.sessionKey}/>
                        </div>
                    </div>
                    :
                    <div inicio style={{
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundImage: <as/>,
                        backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/shotify-8b0ac.appspot.com/o/muchpic%2Fimg%2Fas-2.jpg?alt=media&token=a8173ce9-bcc1-4b43-88af-df61b217cea1")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>

                    </div>
                :
                <div inicio style={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    height: '100vh',
                    // flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {
                        this.state.screenKey
                            ?
                            <QRCode value={this.state.screenKey}/>
                            :
                            <></>
                    }
                </div>
        );
    }
}

export default App;
